<!--
 * @Author: your name
 * @Date: 2022-04-13 09:28:37
 * @LastEditTime: 2024-09-13 14:23:51
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \cloud-diagnos\src\mobile\pages\Home.vue
-->
<!--  -->
<template>
  <div id="home">
    <Header></Header>
    <div class="banner-box">
      <div class="title wow animate__animated animate__fadeInUp">
        <div class="focus">“AI+数字病理”</div>
        <div class="ordinary">的探索者和引领者</div>
      </div>
      <div class="subheading wow animate__animated animate__fadeInUp">
        以创新科技驱动病理诊断，去探索生命中更多的未知，从而创造更加美好的健康生活
      </div>
      <div class="keywords wow animate__animated animate__fadeInUp">
        多学科 多生态 多应用
      </div>
    </div>
    <div class="product-solutions">
      <div class="product-header">
        <h2 class="title wow animate__animated animate__fadeInUp">
          产品及 <br />
          解决方案
        </h2>
        <div class="description wow animate__animated animate__fadeInUp">
          创新精准医疗行业的前沿
        </div>
      </div>
      <ul class="solution-box">
        <!-- <li
          class="solution-item"
          v-for="(item, index) in slides"
          :key="index"
          @click="tapSolution(item)"
        >
          <img class="solution-img" :src="item.src" />
          <div class="solution-text">{{ item.title }}</div>
        </li> -->
        <van-swipe
          :autoplay="3000"
          indicator-color="#0b847b"
          width="100%"
          height="100%"
        >
          <van-swipe-item
            class="solution-item"
            v-for="(item, index) in slides"
            :key="index"
            @click="tapSolution(item)"
          >
            <img class="solution-img" :src="item.src" />
            <div class="solution-text">
              {{ item.title }}
            </div>
          </van-swipe-item>
        </van-swipe>
      </ul>
      <div class="solutions-footer">
        <van-button plain color="#0b847b" @click="tapPage('/product')"
          >了解更多</van-button
        >
      </div>
    </div>

    <div class="news-box">
      <div class="news-header">
        <h2 class="title wow animate__animated animate__fadeInUp">品信动态</h2>
        <div class="description wow animate__animated animate__fadeInUp">
          探究行业热点，掌握最新动态
        </div>
      </div>
      <ul class="news-content">
        <li
          class="news-item"
          v-for="(item, index) in newsList"
          :key="index"
          @click="tapNews(item.id)"
        >
          <div class="news-img">
            <img :src="item.titleImage" alt="" />
          </div>
          <div class="home-content-group">
            <span class="home-content-type">重点资讯</span> ｜
            <span class="date">{{ item.createTime }}</span>
          </div>
          <div class="home-content-title">
            {{ item.title }}
          </div>
        </li>
      </ul>
      <div class="news-footer">
        <van-button plain color="#0b847b" @click="tapPage('/medium')"
          >了解更多</van-button
        >
      </div>
    </div>

    <div class="science-us">
      <div class="science-header">
        <h2 class="title wow animate__animated animate__fadeInUp">学术园地</h2>
        <div class="description wow animate__animated animate__fadeInUp">
          探究行业热点, 掌握最新动态
        </div>
      </div>
      <div class="science-box">
        <div class="decipher">
          <div class="title">文献解读</div>
          <div class="illustration-img">
            <img src="../../../assets/image/home/description.png" alt="" />
          </div>
          <ul class="decipher-list">
            <li
              v-for="item in decipherList"
              :key="item.id"
              @click="tapArticle(item.id)"
            >
              <div class="topic wow animate__animated animate__fadeInUp">
                {{ item.title }}
              </div>
              <div class="subtitle wow animate__animated animate__fadeInUp">
                {{ item.summary }}
              </div>
            </li>
          </ul>
        </div>
        <div class="doctor">
          <div class="title">学术视频</div>
          <div class="illustration-img">
            <img src="../../../assets/image/home/doctor.png" alt="" />
          </div>
          <ul class="video-box">
            <li class="video-item" @click="tapVideo(videoList.id)">
              <img class="video-img" :src="videoList.titleImage" />
              <div class="video-text wow animate__animated animate__fadeInUp">
                {{ videoList.title }}
              </div>
              <div class="times wow animate__animated animate__fadeInUp">
                {{ videoList.createTime }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="science-more">
        <van-button plain color="#0b847b" @click="tapPage('/science')"
          >了解更多</van-button
        >
      </div>
    </div>

    <div class="about-us">
      <div class="about-header">
        <h2 class="title wow animate__animated animate__fadeInUp">关于我们</h2>
        <div class="description wow animate__animated animate__fadeInUp">
          品信生物科技，快速解决行业疑难问题
        </div>
      </div>
      <div class="about-more">
        <van-button plain color="#0b847b" @click="tapPage('/about')"
          >了解更多</van-button
        >
      </div>
      <div class="about-box">
        <div class="about-img"></div>
      </div>
    </div>

    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import api from "../../../api/api";
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      slides: [
        {
          title: "DNA倍体癌症早筛解决方案",
          path: "/product/dna",
          src: require("@/assets/image/home/solution1.jpg"),
        },
        {
          title: "血液细胞形态诊断解决方案",
          path: "/product/blood",
          src: require("@/assets/image/home/solution2.jpg"),
        },
        {
          title: "宫颈癌筛查解决方案",
          path: "/product/tbs",
          src: require("@/assets/image/home/solution3.jpg"),
        },
        {
          title: "远程病理诊断解决方案",
          path: "/product/telepathology",
          src: require("@/assets/image/home/solution4.jpg"),
        },
      ],
      newsList: [],
      decipherList: [],
      videoList: {},
    };
  },
  created() {
    this.getFocusNews(6);
    this.getDecipherList();
    this.getVideoList();
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {},
  methods: {
    // 分页获取文献列表
    getDecipherList(page = 1) {
      api
        .getDecipherByPage(page)
        .then((res) => {
          // console.log(res.data);
          if (res.data.code == 200) {
            let result = res.data.models;
            if (result.length <= 2) {
              this.decipherList = result;
            } else {
              this.decipherList = result.slice(0, 2);
            }
            console.log(this.decipherList);
          }
        })
        .catch((error) => {});
    },
    // 分页获取视频列表
    getVideoList(page = 1) {
      api
        .getVideoByPage(page)
        .then((res) => {
          // console.log(res.data);
          if (res.data.code == 200) {
            let result = res.data.models;
            result.forEach((item) => {
              let times = item.createTime ? item.createTime.split(" ")[0] : "";
              let moment = times ? times.split("-") : [];
              item.year = moment[0];
              item.month = moment[1];
              item.day = moment[2];
            });
            this.videoList = result[0];
            // console.log(this.videoList);
          }
        })
        .catch((error) => {});
    },
    // 获取焦点新闻
    getFocusNews(num) {
      api
        .getFocusNews(num)
        .then((res) => {
          console.log(res.data);
          let result = res.data.models;
          result.forEach((item) => {
            if (item.createTime) {
              item.createTime = item.createTime.split(" ")[0];
            } else {
              item.createTime = "";
            }
          });
          this.newsList = result;
        })
        .catch((error) => {});
    },
    tapSolution(data) {
      this.$router.push(data.path);
    },
    tapNews(id) {
      this.$router.push({
        path: `/MediumDetail/${id}`,
      });
    },
    // 点击文献解读
    tapArticle(id) {
      this.$router.push({
        path: `/decipherDetail/${id}`,
      });
    },
    // 点击学术视频
    tapVideo(id) {
      this.$router.push({
        path: `/doctorDetail/${id}`,
      });
    },
    tapPage(path) {
      this.$router.push({ path: path });
    },
  },
};
</script>
    
<style lang="scss">
#home {
  width: 100%;
  height: 100%;
  padding-top: 3.75rem;
  // overflow-y: auto;
  .banner-box {
    position: relative;
    width: 100%;
    // height: 42rem;
    height: calc(100vh - 3.75rem);
    // margin-bottom: 4rem;
    margin-bottom: 2rem;
    background-image: url("../../../assets/mobile_img/home/banner.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    .title {
      position: absolute;
      top: 12%;
      left: 5%;
      display: flex;
      color: #212121;
      align-items: center;
      .focus {
        font-family: "OPlusSans3-Bold";
        font-size: 1.6rem;
        color: #0b847b;
      }
      .ordinary {
        font-size: 1.2rem;
        margin-left: 0.5rem;
        font-family: "Microsoft YaHei";
        font-weight: 600;
      }
    }
    .subheading {
      position: absolute;
      top: 22%;
      left: 5%;
      font-size: 1.1rem;
      color: #212121;
      width: 90%;
      line-height: 1.5;
      font-family: "Microsoft YaHei";
      opacity: 0.9;
    }
    .keywords {
      position: absolute;
      top: 40%;
      left: 5%;
      font-family: "OPlusSans3-Medium";
      color: #212121;
      font-size: 1.8rem;
    }
  }
  .product-solutions {
    margin-bottom: 2rem;
    .product-header {
      padding: 2.8rem 1.25rem;
      .line {
      }
      .title {
        position: relative;
        padding-top: 1.5rem;
        margin-bottom: 2.5rem;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 4.5rem;
          height: 0.625rem;
          background-color: #0b847b;
          -webkit-transition: width 0.5s ease-out 0.8s;
          transition: width 0.5s ease-out 0.8s;
        }
      }
      .description {
      }
    }
    .solution-box {
      padding: 0 1.25rem;
      .solution-item {
        padding: 1rem 0;
        .solution-img {
          width: 100%;
          height: 15.65rem;
        }
        .solution-text {
          padding: 1rem 1.25rem;
          font-size: 1.2rem;
          font-family: "SourceHanSansSC-Medium";
          background-color: #fafafa;
        }
      }
      .van-swipe__indicators {
        bottom: 6rem;
        .van-swipe__indicator {
          background-color: #000;
        }
      }
    }
    .solutions-footer {
      padding: 1rem 1.25rem;
    }
  }
  .news-box {
    margin-bottom: 2rem;
    .news-header {
      padding: 2.8rem 1.25rem;
      .line {
      }
      .title {
        position: relative;
        padding-top: 1.5rem;
        margin-bottom: 2.5rem;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 4.5rem;
          height: 0.625rem;
          background-color: #0b847b;
          -webkit-transition: width 0.5s ease-out 0.8s;
          transition: width 0.5s ease-out 0.8s;
        }
      }
      .description {
      }
    }
    .news-content {
      padding: 0 1.25rem;
      .news-item {
        padding: 1rem 0;
        border-bottom: 1px solid #dcdddd;
        .news-img {
          margin-bottom: 0.5rem;
          img {
            width: 100%;
          }
        }
        .home-content-group {
          font-size: 0.75rem;
          .home-content-type {
            color: #0b847b;
          }
        }
        .home-content-title {
          margin-top: 0.625rem;
          font-size: 1rem;
          margin-bottom: 1rem;
          font-family: "SourceHanSansSC-Medium";
        }
      }
    }
    .news-footer {
      padding: 1rem 1.25rem;
    }
  }
  .science-us {
    margin-bottom: 2rem;
    .science-header {
      padding: 2.8rem 1.25rem 1.5rem;
      .line {
      }
      .title {
        position: relative;
        padding-top: 1.5rem;
        margin-bottom: 2.5rem;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 4.5rem;
          height: 0.625rem;
          background-color: #0b847b;
          -webkit-transition: width 0.5s ease-out 0.8s;
          transition: width 0.5s ease-out 0.8s;
        }
      }
      .description {
      }
    }
    .science-more {
      padding: 1rem 1.25rem;
    }
    .science-box {
      padding: 0 1.25rem;
      .decipher {
        border-bottom: 0.0625rem solid #dcdddd;
        margin-bottom: 2rem;
        .title {
          font-size: 1.2rem;
          font-family: "SourceHanSansSC-Medium";
          margin-bottom: 0.8rem;
        }
        .illustration-img {
          width: 100%;
          margin-bottom: 0.8rem;
          img {
            width: 100%;
            border-radius: 1rem;
          }
        }
        .decipher-list {
          li {
            position: relative;
            margin-bottom: 1.5rem;
            cursor: pointer;
            &:first-child {
              margin-bottom: 3rem;
              &::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: -1.5rem;
                width: 50%;
                height: 5px;
                background-color: #0b847b;
              }
            }
            .topic {
              font-size: 1rem;
              font-family: "SourceHanSansSC-Medium";
              margin-bottom: 0.5rem;
            }
            .subtitle {
              font-size: 0.9rem;
              color: #666666;
              font-family: "OPlusSans3-Regular";
              line-height: 1.8;
            }
          }
        }
      }
      .doctor {
        .title {
          font-size: 1.2rem;
          font-family: "SourceHanSansSC-Medium";
          margin-bottom: 0.8rem;
        }
        .illustration-img {
          width: 100%;
          margin-bottom: 0.8rem;
          img {
            width: 100%;
            border-radius: 1rem;
          }
        }
        .video-box {
          // padding: 0 1.25rem;
          .video-item {
            padding: 1rem 0;
            // border-bottom: 1px solid #ddd;
            .video-img {
              width: 100%;
            }
            .video-text {
              padding: 0.8rem 0.5rem;
              font-size: 1rem;
              font-family: "SourceHanSansSC-Medium";
              background-color: #fafafa;
            }
            .times {
              color: #666666;
              padding: 0.5rem;
              font-family: "OPlusSans3-Regular";
              font-size: 0.75rem;
              background-color: #fafafa;
            }
          }
        }
      }
    }
  }
  .about-us {
    margin-bottom: 2rem;
    .about-header {
      padding: 2.8rem 1.25rem 1.5rem;
      .line {
      }
      .title {
        position: relative;
        padding-top: 1.5rem;
        margin-bottom: 2.5rem;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 4.5rem;
          height: 0.625rem;
          background-color: #0b847b;
          -webkit-transition: width 0.5s ease-out 0.8s;
          transition: width 0.5s ease-out 0.8s;
        }
      }
      .description {
      }
    }
    .about-more {
      padding: 1rem 1.25rem;
    }
    .about-box {
      padding: 0 1.25rem;
      .about-img {
        width: 100%;
        height: 11.5rem;
        background-image: url("../../../assets/image/home/about.jpg");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }
  .footer {
    background: #f5f5f5;
  }
}
</style>